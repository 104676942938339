import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// import Seo from '../../../components/seo'
// import Layout from '../../../components/layout'
// import Hero from '../../../components/hero'
import TagPreview from '../../../components/tag-preview'

class TagIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulTag.nodes')
    return (
    //   <Layout location={this.props.location}>
    //     <Seo title="Tags" />
    //     <Hero title="Tags" />
        <TagPreview posts={posts} />
    //   </Layout>
    )
  }
}

export default TagIndex

export const pageQuery = graphql`
  query TagIndexQuery {
    allContentfulTag {
      nodes {
        name
      }
    }
  }
`
